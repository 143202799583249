export default {
  auth: {
    login: {
      title: "Operations Services",
      subtitle: "IMS mobile",
      buttonText: "Log in",
    },
    logout: {
      menuItemText: "Log out",
    },
  },
  sideMenu: {
    maintenanceTaskSection: "Maintenance",
    inventoryCheckSection: "Inventory Check",
    safetySection: "Safety",
    competencyManagementSection: "Competency Management",
  },
  routes: {
    maintenanceTasksOnline: "Overview",
    maintenanceTasksOffline: "Downloaded",
    safetyMaintenanceTasksOnline: "Overview",
    safetyMaintenanceTasksOffline: "Downloaded",
    createCorrectiveMaintenanceTask: "Service Request",
    createMaintenanceTaskFromTemplate: "Create from template",
    maintenanceTaskDetailsOverview: "Overview",
    maintenanceTaskDetailsProcessSteps: "Process steps",
    maintenanceTaskProcessStepsDetails: "Maintenance task process step",
    myMaintenanceTaskDetailsParts: "Parts Used",
    maintenanceTaskDetailsPersonnelAssignments: "Personnel Assignments",
    myOpenMaintenanceTask: "Resume",
    myOpenSafetyMaintenanceTask: "Resume",
    appInfo: "App Info",
    competencyManagementOverview: "Overview",
    toBeSigned: "To be signed",
    myCompetencyManagementTrainings: "Downloaded",
    myOpenCompetencyManagementResource: "Resume",
    inventoryChecksOnline: "Overview",
    inventoryChecksOffline: "Downloaded",
    inventoryCheckDetailsOverview: "Overview",
    inventoryCheckDetailsArticles: "Articles",
    myOpenInventoryCheck: "Resume",
    inventory: "Inventory",
  },
  maintenanceTaskText: "Maintenance task",
  inventoryCheckText: "Inventory check",
  maintenanceTaskProcessStep: {
    downloadedHeaderText: "Downloaded Work order",
  },
  inventoryCheck: {
    overviewHeaderText: "Inventory Check Overview",
    downloadedHeaderText: "Downloaded Inventory Checks",
    reportingNumberText: "No.",
    articlesText: "Articles",
    subjectText: "Subject",
    assigneeText: "Assignee",
    createdAtText: "Created at",
    stateText: "State",
    remarksText: "Remarks",
    deleteSuccessText: "The inventory check has been deleted from application.",
    blocked: {
      byMeOnThisDeviceText: "The inventory check is blocked by you.",
      byMeOnOtherDeviceText:
        "The inventory check is blocked by you on other device.",
      byOtherStaffText: (staffName: string) =>
        `The inventory check is blocked by ${staffName}.`,
      isBlockedBecauseItIsValidatedText:
        "The inventory check is blocked because it is already validated.",
      isBlockedBecauseItIsCompletedText:
        "The inventory check is blocked because it is already completed.",
    },
    state: {
      scheduled: "Scheduled",
      completed: "Completed",
      validated: "Validated",
    },
    stockText: "Stock",
    amountText: "Amount",
    hasChangedText: "Has changed?",
    amountCorrectText: "Amount correct?",
    article: {
      descriptionText: "Remarks",
      newAmountText: "New amount",
      hasChanged: {
        yesText: "yes",
        noText: "no",
        notSetText: "not set",
      },
      amountCorrect: {
        yesText: "yes",
        noText: "no",
        notSetText: "not set",
      },
    },
  },
  maintenanceTask: {
    createdForMaintenanceTaskProcessStepText:
      "Corrective created for maintenance task process step",
    overviewHeaderText: "Maintenance Overview",
    downloadedHeaderText: "Downloaded Work Orders",
    type: {
      preventive: "Preventive",
      corrective: "Corrective",
      failure: "Failure",
      carp: "Carp",
      modification: "Modification",
      ppeInspection: "Ppe inspection",
      eqInspection: "Eq inspection",
    },
    state: {
      scheduled: "Scheduled",
      completed: "Completed",
      validated: "Validated",
      requested: "Requested",
    },
    downloadSuccessText: (count: number) =>
      `${count} maintenance task(s) has been blocked and downloaded.`,
    syncText: "Sync",
    syncSuccessText: (count: number) =>
      `${count} maintenance task(s) has been synced and unblocked.`,
    deleteSuccessText:
      "The maintenance task has been deleted from application.",
    deleteTitle:
      "Do you really want to remove your locally saved maintenance task?",
    scheduledDateText: "Scheduled",
    scheduledDateHeader: "Scheduling",
    reportingNumberText: "No.",
    windowText: "Window",
    timeDeviationText: "Time deviation",
    serializedArticleText: "Serialized Article",
    serializedArticleLocationText: "Location",
    descriptionText: "General Instructions and H&S Information",
    nameText: "Name",
    maintainedAtValueText: "Maintained at value",
    maintainedAtValueDescription: (parameterName: string) =>
      `What was the value of meter ${parameterName} while maintenance?`,
    maintainedAtValuePostDescription: (scheduledValue: number) =>
      `[Scheduled at: ${scheduledValue}]`,
    stateText: "State",
    typeText: "Type",
    templateText: "Template",
    completionText: "Completion",
    expectedStartText: "Expected Start",
    expectedEndText: "Expected End",
    scheduledStartText: "Scheduled Start",
    scheduledEndText: "Scheduled End",
    completionStartText: "Start",
    completionEndText: "End",
    remarksText: "Remarks",
    presumedCausesText: "Presumed Causes",
    observationalIndicatorsText: "Observational Indicators",
    processStep: {
      detailsText: "Process step details",
      listText: "Process step list",
      inventoryText: "Inventory",
      nameText: "Name",
      checkResult: {
        notSetText: "Not set",
        goodYesText: "Yes",
        goodNoText: "No",
      },
      actionPerformed: {
        notSetText: "",
        goodYesText: "Yes",
      },
      remarksText: "Remarks",
      fixText: "Fix",
      quickFixText: "Quick fix",
      correctiveText: "Corrective",
      correctiveExistText: "Corrective has been already created.",
      addCorrectiveText: (processStepName: string) =>
        `Add Corrective to process step '${processStepName}'`,
      correctiveTemplateText: "Corrective template",
      correctiveContainsErrorsText: "Corrective contains errors.",
      attachmentsText: "Attachments",
      noAttachmentsText: "There are no attachments",
      attachments: {
        countText: (counter: number) => `${counter} attachment(s)`,
        noText: `There is no attachments.`,
      },
    },
    processStepActionText: "Action",
    processStepTypeText: "Type",
    processStepComponentText: "Component",
    processStepMeasureText: "Measure",
    processStepMeasureValueText: "Value",
    processStepMeasureUnitText: "Unit",
    processStepMeasureValueMinText: "Value Min",
    processStepMeasureValueMaxText: "Value Max",
    processStepRemarksText: "Remarks",
    processStepDescriptionText: "Description",
    processStepCheckResultText: "Condition good?",
    processStepActionPerformedText: "Action performed?",
    processStepCorrectiveMaintenanceText: "Corr. Maint.",
    processStepRestoredText: "Restored",
    blocked: {
      byMeOnThisDeviceText: "The maintenance task is blocked by you.",
      byMeOnOtherDeviceText:
        "The maintenance task is blocked by you on other device.",
      byOtherStaffText: (staffName: string) =>
        `The maintenance task is blocked by ${staffName}.`,
      outOfPMWindowText:
        "The maintenance task is blocked because it is outside the permitted PM Window.",
      isBlockedBecauseItIsValidatedText:
        "The maintenance task is blocked because it is already validated.",
    },
    personnelAssignmentsText: "Work Order Completion",
    personnelAssignmentsSecondSetOfEyesText: "Final Verification",
    personnelAssignmentsSecondSetOfEyesSubtitleText:
      'This section only needs to be completed if a "Final Verification" process step exists in PROCESS STEPS.',
    personnelAssignments: {
      type: {
        internalText: "Internal",
        externalText: "External",
      },
      typeText: "Internal",
      nameText: "Name",
      positionOrCompanyText: "Position/Company",
      positionText: "Position",
      companyText: "Company",
      totalMenHoursText: "Total Men Hours",
      selectStaffText: "Select Staff",
      responsibilityText: "Responsibility",
      durationText: "Duration (h:m)",
      responsibility: {
        leadText: "Lead",
        supportText: "Support",
        helperText: "Helper",
        traineeText: "Trainee",
      },
    },
    sign: {
      completedConfirmationText: "Sign.",
      signAsLeadConfirmationText:
        "I herby confirm that I performed the maintenance according to MTE-2001 Preventive and Corrective Maintenance Procedure.",
      signAsFinalVerificationConfirmationText:
        "I herby confirm that I performed the final verification according to MTE-2001 Preventive and Corrective Maintenance Procedure.",
    },
    assistant: {
      type: {
        internalText: "Internal",
        externalText: "External",
      },
    },
    stateChangeErrorText: (newState: string) =>
      `State change to ${newState} caused errors in maintenance task. Broken fields are highlighted.`,
    sync: {
      unavailableReason: {
        notValidText:
          "The work order is not valid. Go to the details page and fix it.",
        isOpenText:
          "The work order is still open. Go to the details page and stop it.",
        offlineText: "You are offline.",
        noPermissionsText: "You do not have permissions to perform sync.",
        hasNotChangedText:
          "The work order has not changed. To remove it from the list use the remove button.",
        notProcessableText: "The work order is not processable.",
        stateIsNotSupportedText: "The work order state is not supported.",
        someMaintenanceTasksCreatedForMaintenanceTaskProcessStepsAreOpen:
          "The work order's related correctives are still open.",
      },
    },
    parts: {
      stockArticleText: "Article (Stock)",
      amountText: "Amount",
      unitText: "Unit",
      insufficientAmountOnStockText: (onStock: number) =>
        `${onStock} on stock (amount may be outdated)`,
    },
    serviceRequestText: "Service Request",
    fromTemplateText: "From template",
    windowMessageText: (window: string) =>
      `The permitted window for this Work Order is ${window} days.`,
    theEntityRelatesToOriginalMaintenanceTaskProcessStep: (
      processStepName: string
    ) =>
      `This Maintenance Task was created as a corrective for Maintenance Task Process Step '${processStepName}'`,
  },
  attachment: {
    attachmentsText: "Attachments",
    noAttachmentsText: "There are no attachments",
    deleteTitle: "Do you really want to remove attachment?",
  },
  safetyMaintenanceTask: {
    overviewHeaderText: "Safety Overview",
    downloadedHeaderText: "Downloaded Safety",
  },
  stockArticle: {
    externalIdText: "ID",
    nameText: "Name",
    stockText: "Stock",
    amountText: "Amount",
  },
  toBeSignedText: "To be signed",
  toBeSigned: {
    entityTypeText: "Entity type",
    nameText: "Name",
    typeText: "Type",
    scheduledDateText: "Scheduled",
    itemNoAbleToSignText: "Item is not able to sign.",
  },
  competencyManagement: {
    completedConfirmationText:
      "I herby confirm that I performed the training / assessment according to CMS-1001 Competency Management System.",
    signConfirmationText:
      "I herby confirm that I received the training / assessment according to CMS-1001 Competency Management System.",
    overviewHeaderText: "Training Overview",
    downloadedHeaderText: "Downloaded Trainings & Assessments",
    openTitleText: "Resume",
    reportingNumber: "No.",
    nameText: "Name",
    assigneeText: "Assignee",
    trainerText: "Trainer",
    typeText: "Type",
    scheduledDateText: "Scheduled",
    stateText: "State",
    trainingText: "Training",
    trainingCustomText: "Training Custom",
    assessmentText: "Assessment",
    training: {
      reportingNumberText: "No.",
      toBeSignedText: "Training to be signed",
      overviewTitleText: "Overview",
      sessionsTitleText: "Sessions",
      state: {
        scheduledText: "Scheduled",
        completedText: "Completed",
      },
      type: {
        initialText: "initial",
        refresherText: "refresher",
      },
      blocked: {
        byMeOnThisDeviceText: "The training is blocked by you.",
        byMeOnOtherDeviceText:
          "The training is blocked by you on other device.",
        byOtherStaffText: (staffName: string) =>
          `The training is blocked by ${staffName}.`,
        isBlockedBecauseItIsCompletedText:
          "The training is blocked because it is already completed.",
        isBlockedBecauseUserHasNoPermissions:
          "The training is blocked because you do not have permissions to edit it.",
      },
      sync: {
        unavailableReason: {
          notValidText: "it is not valid.",
          isOpenText: "it is still open.",
          offlineText: "you are offline.",
          noPermissionsText: "you do not have permissions to perform sync.",
          hasNotChangedText: "has not changed.",
          notProcessableText: "it is not processable.",
          stateIsNotSupportedText: "a state is not supported.",
        },
      },
      deleteSuccessText: "The training has been deleted from application.",
      deleteTitle: "Do you really want to remove your locally saved training?",
      nameText: "Name",
      assigneeText: "Assignee",
      moduleText: "Module",
      trainerText: "Trainer",
      remarksText: "Remarks",
      scheduledDateHeader: "Scheduling",
      expectedText: "Expected at",
      scheduledText: "Scheduled at",
      completionDateHeader: "Completion",
      completionStartDateTimeText: "Start",
      completionEndDateTimeText: "End",
      stateText: "State",
      session: {
        nameHeader: "Name",
        achievedHeader: "Completed",
        remarksHeader: "Remarks",
        remarksText: "Remarks",
        achievedText: "Completed",
      },
    },
    trainingCustom: {
      reportingNumberText: "No.",
      overviewTitleText: "Overview",
      assigneesTitleText: "Assignees",
      typeText: "custom Training",
      deleteSuccessText:
        "The training custom has been deleted from application.",
      deleteTitle:
        "Do you really want to remove your locally saved training custom?",
      assignee: {
        staffHeader: "Staff",
        achievedHeader: "Achieved",
        remarksHeader: "Remarks",
      },
      nameText: "Name",
      trainerText: "Trainer",
      remarksText: "Remarks",
      descriptionText: "Description",
      scheduledText: "Scheduled at",
      completionDateText: "Completed at",
      stateText: "State",
      sync: {
        unavailableReason: {
          notValidText: "it is not valid.",
          isOpenText: "it is still open.",
          offlineText: "you are offline.",
          noPermissionsText: "you do not have permissions to perform sync.",
          hasNotChangedText: "has not changed.",
          notProcessableText: "it is not processable.",
          stateIsNotSupportedText: "a state is not supported.",
        },
      },
    },
    assessment: {
      reportingNumberText: "No.",
      toBeSignedText: "Assessment to be signed",
      overviewTitleText: "Overview",
      objectivesTitleText: "Objectives",
      state: {
        scheduledText: "Scheduled",
        completedText: "Completed",
      },
      type: {
        initialText: "initial",
        refresherText: "refresher",
      },
      blocked: {
        byMeOnThisDeviceText: "The assessment is blocked by you.",
        byMeOnOtherDeviceText:
          "The assessment is blocked by you on other device.",
        byOtherStaffText: (staffName: string) =>
          `The assessment is blocked by ${staffName}.`,
        isBlockedBecauseItIsCompletedText:
          "The assessment is blocked because it is already completed.",
        isBlockedBecauseUserHasNoPermissions:
          "The training is blocked because you do not have permissions to edit it.",
      },
      sync: {
        unavailableReason: {
          notValidText: "it is not valid.",
          isOpenText: "it is still open.",
          offlineText: "you are offline.",
          noPermissionsText: "you do not have permissions to perform sync.",
          hasNotChangedText: "has not changed.",
          notProcessableText: "it is not processable.",
          stateIsNotSupportedText: "a state is not supported.",
        },
      },
      deleteSuccessText: "The assessment has been deleted from application.",
      deleteTitle:
        "Do you really want to remove your locally saved assessment?",
      nameText: "Name",
      assigneeText: "Assignee",
      moduleText: "Module",
      trainerText: "Trainer",
      remarksText: "Remarks",
      scheduledDateHeader: "Scheduling",
      expectedText: "Expected at",
      scheduledText: "Scheduled at",
      completionDateHeader: "Completion",
      completionStartDateTimeText: "Start",
      completionEndDateTimeText: "End",
      stateText: "State",
      objective: {
        nameHeader: "Name",
        achievedHeader: "Achieved",
        remarksHeader: "Remarks",
        remarksText: "Remarks",
        achievedText: "Achieved",
      },
    },
  },
  listing: {
    selectedItemsText: (selectedItemsCount: number) =>
      `${selectedItemsCount} selected`,
  },
  startText: "Start",
  generateText: "Generate",
  signText: "Sign",
  stopText: "Stop",
  api: {
    generalError: "Something went wrong. Try again or contact support.",
  },
  generalError: "Something went wrong. Try again or contact support.",
  deviceIdentifierText: "Device Identifier",
  staffId: "API Staff Identifier",
  localStorageUsage: "LocalStorage usage",
  gitCommitHashText: "Git commit hash",
  deleteAllUserDataText: "Delete all user data (you will be log out)",
  deleteAllUserDataButtonText: "Delete",
  validation: {
    fieldIsRequiredText: `Field is required`,
    fieldValueIsNotInRange: (meta: {
      min?: number;
      max?: number;
      value?: number;
    }): string => `Range ${meta.min ?? "x"} - ${meta.max ?? "x"}`,
    valueMustBeDifferentThan: (originalValue: string) =>
      `Value must be different than ${originalValue}.`,
  },
  offline: {
    text: "This feature is not available offline.",
  },
  dialog: {
    confirm: {
      yesText: "Yes",
      noText: "No",
    },
  },
  pleaseSelectText: "Please select",
  newVersionAvailable: {
    text: "A new version is available.",
    buttonText: "Get an update",
  },
  camera: {
    titleText: "Take photo",
    buttonText: "Take photo",
  },
  imagePreviewText: "Image preview",
  allText: "All",
  downloadText: "Download",
  syncText: "Sync",
  groupEditText: "Group edit",
  formContainsErrorsText: "The form contains errors.",
  yesText: "Yes",
  noText: "No",
  download: {
    downloadSuccessText: (count: number) =>
      `${count} item(s) has been blocked and downloaded.`,
    downloadAgainSuccessText: (count: number) =>
      `${count} item(s) has been downloaded again on your device.`,
  },
  sync: {
    syncSuccessText: (count: number): string =>
      `${count} item(s) has been synced and unblocked.`,
    syncIsNotPossibleText: (props: {
      type: string;
      identifier: string;
      reason: string;
    }): string =>
      `${props.type} ${props.identifier} is not possible to sync, because ${props.reason}`,
  },
  sign: {
    signSuccessText: "Item has been signed.",
  },
  groupEdit: {
    notPossibleReason: {
      noEntityText: "There are no entities.",
      openCloseStateIsDifferentText: "One of entity is open in single mode.",
      differentTypesText: "Type is not the same for all entities.",
      entitiesAreNotSimilarText: "Entities are not similar.",
      typeIsNotSupportedText: (type: string) =>
        `Entity type ${type} is not supported.`,
    },
  },
  staffDeletedText: "staff deleted",
  deleteTitle: "Do you really want to remove your locally saved item?",
} as const;
